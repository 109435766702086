<template>
  <div>
    <LineChart v-if="chartData" :data="chartData"
               :options="{responsive: true, stepped: true, elements: { line: {backgroundColor: 'white', borderColor: 'white', borderWidth: 1}, point:{ pointStyle: false}}, plugins: { tooltip: { callbacks: { label: graphLabel } } }, scales: { y: { ticks: { callback: function(value, index, ticks) {return formatMoney(value); } } }}}"/>
    <v-data-table
      v-if="holdings && holdings.length > 0"
      :items="holdingsToDisplay"
      :headers="[
        { value: 'accountId', text: 'Account' },
        { value: 'name', text: 'Name' },
        { value: 'quantity', text: 'Quantity' },
        { value: 'price', text: 'Price' },
        // { value: 'expenseRatio', text: 'ER' },
        { value: 'amount', text: 'Amount' },
      ]"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.accountId="{ value }">
        {{ getAccountName(value) }}
      </template>

      <template v-slot:item.amount="{ value }">
        {{ formatMoney(value) }}
      </template>

      <template v-slot:item.price="{ item }">
        {{ formatMoney(item.price) }} ({{item.priceDate}})
      </template>

      <template v-slot:body.append="{ headers }">
        <tr style="  font-weight: bold; font-size: large;">
          <td :colspan="headers.length - 2"></td>
          <td class="text-right">Total</td>
          <td class="text-start">{{formatMoney(total)}}</td>
        </tr>
      </template>
    </v-data-table>
    <hr v-if="purpose.allocations && purpose.allocations.length > 0 && allocations && allocations.length > 0"/>
    <v-data-table
      v-if="purpose.allocations && purpose.allocations.length > 0 && allocations && allocations.length > 0"
      :items="allocations"
      :headers="[
        { value: 'assetClassId', text: 'Asset Class' },
        { value: 'target', text: 'Target' },
        { value: 'actual', text: 'Actual' },
        { value: 'drift', text: 'Drift' },
        { value: 'amount', text: 'Amount' },
        { value: 'sell', text: 'Sell' },
        { value: 'buy', text: 'Buy' },
      ]"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.assetClassId="{ value }">
        <div v-if="assetClasses && assetClasses[value]">
          {{ assetClasses[value].name }}
        </div>
        <div v-else>Other</div>
      </template>
      <template v-slot:item.target="{ value }">
        {{ formatPercent(value) }}
      </template>
      <template v-slot:item.actual="{ value }">
        {{ formatPercent(value) }}
      </template>
      <template v-slot:item.drift="{ value }">
        {{ formatPercent(value) }}
      </template>

      <template v-slot:item.amount="{ value }">
        {{ formatMoney(value) }}
      </template>
      <template v-slot:item.sell="{ value }">
        {{ formatMoney(value) }}
      </template>
      <template v-slot:item.buy="{ value }">
        {{ formatMoney(value) }}
      </template>

      <template v-slot:item.price="{ item }">
        {{ formatMoney(item.price) }} ({{item.priceDate}})
      </template>

      <template v-slot:body.append="{ headers }">
        <tr style="  font-weight: bold; font-size: large;">
          <td :colspan="headers.length - 4"></td>
          <td class="text-right">Total</td>
          <td class="text-start">{{formatMoney(total)}}</td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="showEditPurposeDialog" max-width="800px">
      <Card title="Edit Purpose" icon="mdi-piggy-bank" inDialog>
        <DynamicForm
          :object="purpose"
          :fields="[
          { fieldName: 'name', displayName: 'Name', type:String, rules:[rules.required]},
          { fieldName: 'allocations', displayName: 'Allocations', type:'AssetAllocationList', values: assetClassesForDropdown, addAction: (o) => $emit('addAssetClass', o), rules:[rules.required]},
        ]"
          :deleteMethod="!holdings || holdings.length === 0 ? (o) => this.$api.financial_investments.deletePurpose(o) : null"
          :saveMethod="(o) => $api.financial_investments.savePurpose(o)"
          :cancelCallback="() => this.showEditPurposeDialog = false"
          :successCallback="() => { this.showEditPurposeDialog = false; $emit('saved') }"
        >
        </DynamicForm>
      </Card>
    </v-dialog>

    <v-card-actions>
      <v-switch
        v-if="holdings && holdings.length > 0"
        v-model="showAllAssets"
        label="Show All Assets"
      />
      <v-spacer />
      <v-switch
        v-if="holdings && holdings.length > 0"
        v-model="ignoreCashOnlyAccountsInBalance"
        label="Ignore Credit Accounts in Re-balance"
        @change="refresh"
      />
      <v-spacer />
      <v-btn fab @click="showEditPurposeDialog = true">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import DynamicForm from '@/components/DynamicForm.vue'
import Card from '@/components/Card.vue'
import { Line as LineChart } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

export default {
  name: 'Purpose',
  components: { Card, DynamicForm, LineChart },
  props: ['accounts', 'purpose', 'assetClassesForDropdown', 'assetClasses'],
  data: () => ({
    total: 0,
    holdings: null,
    allocations: null,

    showAllAssets: false,
    ignoreCashOnlyAccountsInBalance: true,
    showEditPurposeDialog: false,

    chartData: false,

    rules: {
      required: value => !!value || 'Required',
    },
  }),
  computed: {
    holdingsToDisplay() {
      if (this.showAllAssets)
        return this.holdings;

      const holdings = [];
      this.holdings.forEach(holding => {
        if (holding.quantity !== 0)
          holdings.push(holding);
      });

      return holdings;
    }
  },
  methods: {
    refresh() {
      this.chartData = null;
      this.holdings = null;
      this.allocations = null;
      this.total = 0;
      this.$api.financial_investments.getHoldings(this.purpose).then(holdings => {
        this.holdings = holdings;

        const allocations = [];
        this.purpose.allocations.forEach(assetClass => {
          allocations.push({
            assetClassId: assetClass.assetClassId,
            target: assetClass.weight,
            amount: 0,
            isFromPurposeAllocation: true,
          });
        });

        let otherTotal = 0;
        const otherClasses = {};
        holdings.forEach(holding => {
          this.total += holding.amount;

          let allocationLeft = 100;
          if (holding.allocations && holding.allocations.length > 0) {
            holding.allocations.forEach(holdingAllocation => {
              let allocationFound = false;
              allocations.forEach(allocation => {
                if (holdingAllocation.assetClassId === allocation.assetClassId) {
                  allocationFound = true;
                  allocation.amount += holding.amount * holdingAllocation.weight / 100;
                  allocationLeft = allocationLeft - holdingAllocation.weight;
                }
              });
              if (!allocationFound) {
                otherClasses[holdingAllocation.assetClassId] = (otherClasses[holdingAllocation.assetClassId] || 0) + (holding.amount * holdingAllocation.weight / 100);
                allocationLeft = allocationLeft - holdingAllocation.weight;
              }
            });
          }

          otherTotal += holding.amount * allocationLeft / 100;
        });

        this.$emit('totalChanged', this.total);

        for (const id in otherClasses) {
          allocations.push({
            assetClassId: id,
            target: 0,
            amount: Math.round(otherClasses[id] * 100) / 100,
          });
        }

        if (Math.round(otherTotal * 100) !== 0) {
          allocations.push({
            target: 0,
            amount: Math.round(otherTotal * 100) / 100,
          });
        }

        let ignoreAmount = 0;
        if (this.ignoreCashOnlyAccountsInBalance) {
          allocations.forEach(allocation => {
            if (allocation.assetClassId === "credit") {
              ignoreAmount += allocation.amount;
            }
          });
        }

        allocations.forEach(allocation => {
          allocation.amount = Math.round(allocation.amount * 100) / 100;
          if (!this.ignoreCashOnlyAccountsInBalance || allocation.assetClassId !== "credit") {
            allocation.actual = allocation.amount * 100 / (this.total - ignoreAmount);
            allocation.drift = (allocation.actual - allocation.target);
            if (allocation.drift > 0)
              allocation.sell = Math.round(allocation.drift * (this.total - ignoreAmount)) / 100;
            else
              allocation.buy = Math.round(allocation.drift * (this.total - ignoreAmount)) / -100;
          }
        });

        this.allocations = allocations.filter(a => !!a.amount || a.isFromPurposeAllocation);
      });

      this.$api.financial_investments.getValuesByAccountIds(this.purpose).then(chartData => {
        this.chartData = chartData;
      });
    },
    getAccountName(id) {
      let name = "Unknown";
      this.accounts.forEach(account => {
        if (account.id === id)
          name = account.name;
      });
      return name;
    },
    graphLabel(context) {
      let label = context.dataset.label || '';

      if (label) {
        label += ': ';
      }
      if (context.parsed.y !== null) {
        label += this.formatMoney(context.parsed.y);
      }
      return label;
    }
  },
  mounted() {
    this.refresh();
  },
  // destroyed () {
  //   console.dir('Destroyed')
  // }
}
</script>

<style scoped>

</style>
